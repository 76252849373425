<template>
  <v-card class="ma-2">
    <v-card-title class="pa-4 pb-3">
      <v-layout row wrap align-center>
        <v-flex lg5 xs12 :class="[xsOnly ? 'mt-3 pl-1 pr-0' : 'px-2']">
          <v-layout align-center>
            <v-flex xl4 lg6 class="pr-1">
              <v-autocomplete hide-details dense outlined label="Class" prepend-inner-icon="mdi-account-group"
                v-model="filterObj.class" @change="getClassroomStudentsList" :items="listOfClasses" item-text="name" item-value="_id" return-object>
              </v-autocomplete>
            </v-flex>
            <!-- <v-flex xl4 lg6 class="pr-1">
              <v-autocomplete hide-details dense outlined :label="$t('student')" prepend-inner-icon="mdi-account"
              v-model="filterObj.student" return-object :items="listOfStudents" item-text="formdata.name" item-value="_id" clearable :disabled="!filterObj.class"
              ></v-autocomplete>
            </v-flex> -->
          </v-layout>
        </v-flex>
        <v-spacer></v-spacer>
        <v-flex lg3 class="d-inline-flex justify-end">
          <v-btn icon dark small @click="goPrevious" class="mt-1" color="black" title="Previous day">
            <v-icon dark small> mdi-arrow-left </v-icon>
          </v-btn>
          <v-menu v-model="filterObj.Menu" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="290px">
            <template #activator="{ on, attrs }">
              <v-text-field style="max-width:130px" v-model="filterObj.date" class="mx-2" hide-details
              prepend-inner-icon="mdi-calendar" readonly v-bind="attrs" v-on="on" outlined dense></v-text-field>
            </template>
            <v-date-picker v-model="filterObj.datePicker" @input="filterObj.Menu = false" :max="currentDate"
              @change="getAttendanceObj(null); filterObj.date = $_formatDate(filterObj.datePicker, 'YYYY-MM-DD', 'DD.MM.YYYY') "></v-date-picker>
          </v-menu>
          <v-btn icon dark small @click="goNext" color="black" class="mt-1" title="Next day">
            <v-icon dark small> mdi-arrow-right </v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-card-title>
    <v-divider></v-divider>
    <template v-if="filterObj.class">
      <v-card-text class="pa-0 overflow-y-auto" style="max-height:61vh">
        <v-simple-table>
          <thead>
            <tr>
              <th style="width: 10%"> Student </th>
              <th style="width: 80%" class="attendanceTypeRadio"> 
                <v-layout>
                  <v-flex v-for="(routine, index) in attendanceObj.attendance" :key="index" class="text-center font-weight-bold">
                    {{routine.routine.isbreak ? routine.routine.title : [routine.routine?.subject?.name].filter(x => x).join(' - ')}}
                  </v-flex>
                </v-layout>
              </th>
              <th style="width: 10%"> Status </th>
            </tr>
            <tr>
              <th></th>
              <th class="attendanceTypeRadio">
                <v-layout>
                  <v-flex  v-for="(routine, routineIndex) in attendanceObj.attendance" :key="routineIndex">
                    <v-radio-group v-model="attendanceObj.attendance[routineIndex].status" hide-details class="ma-0" row  @change="markAllStudents(routineIndex, attendanceObj.attendance[routineIndex].status)">
                      <v-radio  v-for="(i, index) in attendanceTypes" :key="index" :value="i._id">
                        <template #label>
                          <div><strong :style="`color: ${i.color|| '#1976d2'}`">{{i.name}}</strong></div>
                        </template>
                      </v-radio>
                    </v-radio-group>
                  </v-flex>
                </v-layout>
              </th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(student, studentIndex) in listOfStudents" :key="studentIndex">
              <td>
                <span>
                  <v-avatar size="28">
                  <v-img :src="`${imageRootUrl}profile/${student.profile}`"></v-img>
                  </v-avatar>
                </span>
                <span class="mx-3 font-weight-black"> {{student.formdata.name}} </span>
              </td>
              <td class="attendanceTypeRadio">
                <v-layout>
                  <v-flex v-for="(routine, routineIndex) in attendanceObj.attendance" :key="routineIndex">
                    <v-radio-group v-model="attendanceObj.attendance[routineIndex].students[studentIndex].status" hide-details class="ma-0" row>
                      <v-radio  v-for="(i, index) in attendanceTypes" :key="index" :value="i._id">
                        <template #label>
                          <div><strong :style="`color: ${i.color|| '#1976d2'}`">{{i.name}}</strong></div>
                        </template>
                      </v-radio>
                    </v-radio-group>
                  </v-flex>
                </v-layout>
              </td>
              <td>
                <v-chip v-if="student.leaveObj" outlined label color="orange" class="mr-2">Applied For Leave</v-chip>
              </td>
            </tr>
          </tbody>
        </v-simple-table>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn :color="attendanceObj._id ? 'primary' : 'success'" class="text-capitalize" @click="saveOrUpdateAttendance"> Save </v-btn>
        <v-spacer></v-spacer>
      </v-card-actions>
    </template>
    <template v-else>
      <v-card-text>
        <v-alert text color="info">
          <h3 class="my-5"> Select Classroom and Contine </h3>
        </v-alert>
      </v-card-text>
    </template>
  </v-card>
</template>
<script>
import moment from 'moment';
export default {
  props: ['resources', 'events', 'eventTypes', 'configObj'],
  data () {
    return {
      filterObj: {},
      attendanceObj: {},
      searchForClass: "",
      attendanceTypes: [],
      listOfClasses: [],
      listOfStudents: [],
      classRoomAttendaceModel: {
        class: '',
        updatedBy: '', 
        date: '',
        slot: '',
        users: {
          'userid': {
            attendanceMarkedAs: 'attendanceTypeId',
            note: ''
          }
        }
      },
      calendarObj: {
        totalDays: 0,
        month: 0,
        year: 2022,
        title: '',
        dateObj: moment(),
      },
      slotItems: [],
    }
  },
  mounted () {
    this.getAttendanceTypesList()
    this.getClassRoomsList()
    this.$set(this.filterObj, 'date', moment().format('DD.MM.YYYY'))
    this.$set(this.filterObj, 'datePicker', moment().format('YYYY-MM-DD'))
  },
  methods: {
    goNext() {
      var momentObj = moment(this.filterObj.datePicker).add(1, 'days')
      this.$set(this.filterObj, 'date', momentObj.format('DD.MM.YYYY'))
      this.$set(this.filterObj, 'datePicker', momentObj.format('YYYY-MM-DD'))
      this.getAttendanceObj()
    },
    goPrevious() {
      var momentObj = moment(this.filterObj.datePicker).subtract(1, 'days')
      this.$set(this.filterObj, 'date', momentObj.format('DD.MM.YYYY'))
      this.$set(this.filterObj, 'datePicker', momentObj.format('YYYY-MM-DD'))
      this.getAttendanceObj()
    },
    getAttendanceTypesList () {
      this.$_execute('get', 'attendance-types').then(({ data }) => {
        this.attendanceTypes = data
      })
    },
    getClassRoomsList () {
      let query = [{
        $lookup: {
          from: "Courses",
          foreignField: "_id",
          localField: "courseId",
          as: "course",
        },
      }, {
        $unwind: { 
          path: "$course",
          preserveNullAndEmptyArrays: true
        },
      }]
      this.$_execute('post', 'classes/query', query).then(({ data }) => {
        data.forEach(x => {
          if (x.courseId) x.name = x.course?.name
        })
        this.listOfClasses = data
      })
    },
    getClassroomStudentsList () {
      if (this.filterObj.class) {
        this.$_execute('get', `classes/${this.filterObj.class._id}/get-students`).then(({ data = [] } = {}) => {
          this.listOfStudents = data.map((x) => { return { ...x.studentId, student: x.studentId._id } })
          this.getAttendanceObj()
          this.getClassRoutines()
        })
      }
    },
    getClassRoutines () {
      return new Promise((resolve) => {
        const weekday = this.$moment(this.filterObj.datePicker, 'YYYY-MM-DD').day()
        this.$_execute('get', `classes/get-routines?class=${this.filterObj.class._id}&weekday=${weekday}`).then(({ data }) => {
          resolve(data)          
        })
      })
    },
    async getAttendanceObj () {
      // if (this.filterObj.datePicker) this.staffLeaveItems = await this.getStaffsLeave({ date: this.filterObj.datePicker })
      const start = moment(this.filterObj.datePicker).format('YYYY-MM-DD')
      const query = [{
        $set: { class: { $toString: "$class" } },
      }, {
        $match: {
          date: { $eq: start },
          class: { $eq: this.filterObj.class._id }
        }
      }]
      this.$_execute("post", "studentAttendance/query", query).then(async ({ data }) => {
        let modelObj = {}
        if (data && data.length) {
          modelObj = { ...data[0] }
          console.log(modelObj)
          modelObj.dateFormatted = this.$_formatDate(modelObj.date, 'YYYY-MM-DD', 'DD.MM.YYYY')
        } else {
          modelObj = {
            dateFormatted: this.filterObj.date,
            date: this.filterObj.datePicker,
            attendance: []
          }
          const defaultAttendanceType = this.attendanceTypes.find(x => x.isdefault)
          const routines = await this.getClassRoutines()
          routines.filter((routine) => !routine.isbreak).forEach((routine) => {
            const students = this.$_copy(this.listOfStudents)
            let status = ''
            if (defaultAttendanceType) {
              students.map(x => { x.status = defaultAttendanceType._id })
              status = defaultAttendanceType._id
            }
            modelObj.attendance.push({
              routine: {...routine},
              students: students,
              status: status 
            })
          })
        }
        modelObj.class = this.filterObj.class._id
        this.attendanceObj = modelObj
        console.log(this.attendanceObj)
      })
    },
    markAllStudents(routineIndex, value) {
      const attendanceObj = this.$_copy(this.attendanceObj)
      attendanceObj.attendance[routineIndex].status = value
      attendanceObj.attendance[routineIndex].students.map(x => {
        x.status = value
      })
      this.attendanceObj = { ...attendanceObj }
    },
    saveOrUpdateAttendance () {
      let modelObj = this.$_copy(this.attendanceObj)
      const url = modelObj._id ?  `studentAttendance/${modelObj._id}` : 'studentAttendance';
      const method = modelObj._id ? 'put' : 'post';
      this.$_execute(method, url, modelObj).then(() => {
        this.getAttendanceObj()
      })
    }
  }
}
</script>

<style>

.table.table-lightborder td {
    border-color: rgba(83, 101, 140, 0.08);
}
.table-bordered thead th, .table-bordered thead td {
    border-bottom-width: 2px;
}
.table-bordered th, .table-bordered td {
    border: 1px solid rgba(83, 101, 140, 0.33);
}
.table th, .table td {
    padding: 0.75rem;
    vertical-align: top;
    border-top: 1px solid rgba(83, 101, 140, 0.33);
}
.table {
  font-size: 0.702rem;
}

.status-pilli {
    width: 10px;
    height: 10px;
    border-radius: 30px;
    background-color: #eee;
    display: inline-block;
    margin: 0px;
    padding: 0px;
}
</style>